// export const baseUrl = 'http://150.150.6.114:81/LandingPageREST/rest';
export const baseUrl = 'https://slat.bancatlan.hn/LandingPageREST/rest';
export const mjs = {
    successPinChange: 'Cambio de PIN exitoso.',
    successManageInfo: 'Tus datos han sido actualizados con éxito.',
    successLock: 'Bloqueo temporal exitoso.',
    successUnlock: 'Desbloqueo exitoso.',
    successCreditLimit: 'El límite de crédito de la tarjeta ha sido gestionado con éxito.',
    successInquiry: 'Consulta de movimientos exitosa.',
    _successLock: 'Si deseas realizar reposición de tu tarjeta deberás llamar al Call Center 2280-1010 o visitar la agencia de tu preferencia.',
    _successPinChange: 'Este proceso puede demorar unos minutos, agradecemos tu preferencia y confianza en nuestros servicios.',
    _success: 'Puedes seguir disfrutando de los beneficios de tu tarjeta.',
}
export const title = {
    main : 'Gestión de Tarjetas',
    branding : 'Logo Banco Atlantida',
    inquirySubtitle: 'Consulta de saldos tarjetas de crédito',
    inquiryTitle: 'Consulta de saldos',
    changePINSubtitle: 'Cambio de PIN',
    changePINTitle: 'Cambio de PIN',
    creditLimitSubtitle: 'Modificación de límite de crédito tarjeta adicional',
    creditLimitTitle: 'Modificación de límite de crédito',
    infoSubtitle: 'Actualización de datos',
    infoTitle: 'Actualización de datos',
    lockingSubtitle: 'Bloqueo y desbloqueo temporal',
    lockingTitle: 'Bloqueo y desbloqueo',
}

export const footer = {
    common : 'Para dudas o consultas puedes comunicarte a nuestro Call Center a los teléfonos: Tegucigalpa 2280-1010, San Pedro Sula 2580-1010, La Ceiba 2480-1010',
    pinChange : 'Esta gestión te permite realizar cambio de PIN para tarjetas de crédito Mastercard y tarjetas de débito Visa Chip Sin Contacto',
}